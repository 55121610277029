















































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import {
  apiDeviceCategoryList,
  apiDeviceCategoryStatus,
  apiDeviceCategoryDel,
} from '@/api/imagetru3d'
@Component({
  components: {
    LsDialog,
  },
})
export default class DeviceType extends Vue {
  $refs!: { plTreeTable: any }
  pager = new RequestPaging()

  // 获取设备类型列表
  getDeviceCategoryList(): void {
    this.pager
      .request({
        callback: apiDeviceCategoryList,
        params: { pager_type: 1 },
      })
      .then((res) => {})
  }

  // 设备类型状态调整
  handleStatus(value: number, id: number) {
    apiDeviceCategoryStatus({
      id,
      status: value,
    }).then(() => {
      this.getDeviceCategoryList()
    })
  }

  // 设备类型删除
  handleDelete(id: number) {
    apiDeviceCategoryDel(id).then(() => {
      this.getDeviceCategoryList()
    })
  }

  // 创建当前组件的时候，获取设备类型列表
  created() {
    this.getDeviceCategoryList()
  }
}
